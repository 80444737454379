/** @jsx jsx */

import OrtYaml from '../data/orte.yaml'
import AusstellungYaml from '../data/kultrooms.yaml'
import KategorienYaml from '../data/kategorien.yaml'
import Layout from '../components/layout'
import MySEO from '../components/seo'

import SelectBox from '../components/SelectBox'

import { jsx,Container } from 'theme-ui'
import { shuffle } from '../utils/shuffle'



const Kulturraum = () => {
    
    const orte = OrtYaml.content
    const kategorien = KategorienYaml.content
    const mythema= AusstellungYaml.content
    const thema = shuffle(mythema)
    

    if(orte&&kategorien&&thema)
    return(
        <Layout>
             <MySEO title="Kulturräume im Oberen Mittelrheintal" description="Liste von Kulturräumen im Oberen Mittelrheintal" keywords="Museen, Theater, Ausstellungen, Kunst, Kunsträume, Ateliers"/>

         
             <Container>
           
           <SelectBox kategorien={kategorien} orte={orte} thema={thema} titel="Kulturräume" />
           </Container>
        
        </Layout>
    )
    return null
}
        
export default Kulturraum